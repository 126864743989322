import {Link} from "gatsby";
import React, {useState} from "react";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";

function NavDropdown({ childNodes, label }) {
  const [open, setOpen] = useState(false);

  const Icon = !open ? ChevronDownIcon : ChevronUpIcon;

  return (
    <div className="relative md:ml-3 text-sm">
      <button
        onClick={() => setOpen(!open)}
        className="flex items-center px-1 py-2 text-left w-full">
        <span>{label}</span>
        <Icon className="h-5 w-5" />
      </button>
      {open && (
        <div className="absolute right-0 w-full mt-1 origin-top-right rounded shadow-lg md:w-48 z-top bg-white grid grid-cols-1 gap-2">
          {childNodes.nodes.map(node => <NavLink key={node.id} {...node} />)}
        </div>
      )}
    </div>
  )
}

export default function NavLink({ path, label, childItems, className = "block text-black no-underline md:mt-0 md:ml-3 py-2 px-1 text-sm" }) {

  if (childItems && childItems.nodes.length) {
    return <NavDropdown path={path} label={label} childNodes={childItems} />
  }

  return (
    <Link
      className={className}
      to={path}
    >
      {label}
    </Link>
  )
}

