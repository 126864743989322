import {graphql, Link, useStaticQuery} from "gatsby";
import React  from "react";

function Footer() {
    const { site } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }
      allWpMenuItem(sort: {
        fields: [
          order,
        ],
        order: ASC
      }, filter: {
        menu: { node: { locations: { eq: GATSBY_HEADER_MENU }} }
      }) {
        nodes {
          label
          id
          path
        }
      }
    }
  `);

    return (
      <footer className="bg-white border-t border-gray-200">
        {/*<div className="max-w-6xl p-2 md:p-4 flex flex-wrap items-center justify-between mx-auto">*/}
        {/*  <nav className="text-sm md:p-8 flex flex-col">*/}
        {/*      {menuItems.map((link) => (*/}
        {/*          <Link*/}
        {/*              className="block mt-4 text-black no-underline md:inline-block md:mt-0 md:ml-6"*/}
        {/*              key={link.id}*/}
        {/*              to={link.path}*/}
        {/*          >*/}
        {/*              {link.label}*/}
        {/*          </Link>*/}
        {/*      ))}*/}
        {/*  </nav>*/}
        {/*</div>*/}
        <div className="bg-gray-200">
          <div className="max-w-6xl p-2 md:p-4 mx-auto text-center">
            {site.siteMetadata.title} &copy; {new Date().getFullYear()} - Tutti i diritti riservati - Partita Iva 09088400966 - <Link to="/crediti/" className="underline">Crediti</Link> - <Link to="/privacy-policy/" className="underline">Privacy</Link>
          </div>
        </div>
      </footer>
    );
}

export default Footer;

