import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import {StaticImage} from "gatsby-plugin-image";
import {MailIcon, PhoneIcon} from "@heroicons/react/outline";
import NavLink from "./navlink";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  
  const { site, allWpMenuItem: { nodes: menuItems } } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          email
          phone
        }
      }
      allWpMenuItem(sort: {
        fields: [
          order,
        ],
        order: ASC
      }, filter: {
        menu: { node: { locations: { eq: GATSBY_HEADER_MENU }} }, parentId:{ eq: null }
      }) {
        nodes {
          label
          id
          path
          childItems {
            nodes {
              label
              id
              path
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="border-b hidden md:block bg-white">
        <div className="flex flex-wrap items-center justify-between max-w-6xl p-2 mx-auto text-sm underline">
          <a href={`mailto:${site.siteMetadata.email}`} className="flex items-center text-third"><MailIcon className=" mr-1 h-5 w-5" />{site.siteMetadata.email}</a>
          <a href={`tel:${site.siteMetadata.phone}`} className="flex items-center text-third"><PhoneIcon className="mr-1  h-5 w-5" />{site.siteMetadata.phone}</a>
        </div>
      </div>

      <header className="bg-white shadow-md">
        <div className="flex flex-wrap items-center justify-between max-w-6xl p-2 mx-auto md:p-4">
          <Link to="/">
            <div className="flex items-center text-black no-underline">
              <StaticImage src="../images/logo.png" alt="logo dottoressa arianna barazzetti"
                           height={20}
                           placeholder="tracedSVG"
              />
            </div>
          </Link>

          <button
            className="items-center block px-3 py-2 text-primary border border-primary rounded md:hidden focus:border-within-primary"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>

          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:flex md:items-center w-full md:w-auto`}
          >
            {menuItems.map((link) => (
              <NavLink
                key={link.id} {...link} />
            ))}
            <NavLink label="Contattami" path="#contact-form" className="block text-white bg-primary no-underline md:mt-0 md:ml-3 py-2 px-3 text-sm rounded"/>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
