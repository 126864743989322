import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
  import {SEOContext} from 'gatsby-plugin-wpgraphql-seo';

export const SeoWrapper = ({children}) => {
  const {
    allWp: { nodes },
  } = useStaticQuery(graphql`
        query SiteInfoQuery {
            allWp {
              nodes {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
              }
            }
        }
    `);

  const { seo } = nodes[0];

  return (
    <SEOContext.Provider value={{global: seo}}>
      {children}
    </SEOContext.Provider>
  );
};