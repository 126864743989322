import PropTypes from "prop-types";
import React from "react";
import CookieConsent from "react-cookie-consent";

import Header from "./header";
import Footer from "./footer";
import {SeoWrapper} from "./seo-wrapper";

function Layout({ children, layoutType = 'column-1' }) {
  let mainCName = "flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16"
  if (layoutType === 'homepage') {
    mainCName = "w-full"
  }

  return (
    <SeoWrapper>
      <div className="flex flex-col min-h-screen font-sans text-gray-900">
        <Header />

        <main className={mainCName}>
          {children}
        </main>

        <Footer />
      </div>
      <CookieConsent
        location="bottom"
        debug={process.env.NODE_ENV === 'development'}
        buttonText="OK"
        disableStyles
        containerClasses="bg-third bottom-0 fixed flex flex-col md:flex-row text-center md:text-left md:justify-between w-full p-3 z-top left-0 items-center md:px-8"
        buttonClasses="p-1 rounded bg-primary text-white shadow-md px-3"
        contentClasses="text-white text-sm mb-4 md:mb-0"
      >
        Questo sito utilizza strumenti di analytics <br /><span className="text-xs">Consulta la <a className="underline" href="/privacy-policy/">Privacy policy</a> per maggiori informazioni</span>
      </CookieConsent>
    </SeoWrapper>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
